import React from "react";
import MediaQuery from "react-responsive";

import netflixAirbnbCard from "../images/netflix_airbnb_card.svg";
import transferMoneyCard from "../images/transfer_money_card.svg";
import paymentHistoryCard from "../images/payment_history_card.svg";
import smartTipsCard from "../images/Banner.svg";
import { useTranslation, Trans } from "react-i18next";

import "../App.css";

const TakeControlPageFunction = (
	refObj: React.RefObject<HTMLDivElement>) =>
{
    const { t, i18n } = useTranslation();
    return (
        <div ref={refObj}>
            <div style={{ backgroundColor: "rgb(250,252,253)" }}>
                <div
                    style={{
                        backgroundColor: "#fff",
                        padding: "50px 0 50px 0"
                    }}
                >
                    <div
                        style={{
                            paddingLeft: "5vw",
                            width: "100vw"
                        }}
                    >
                        <h2
                            className="App-header Small-top-header-style"
                            style={{ textAlign: "left" }}
                        >
                            {/* Manage your finances */}
                            <Trans i18nKey="homepage.takecontrol.part1"/>
                        </h2>
                        <br />
                        <h1
                            className="App-header"
                            style={{
                                textAlign: "left"
                            }}
                        >
                            <span style={{ color: "#6432FF"}}>
                                {/* Take control */}
                                <Trans i18nKey="homepage.takecontrol.part2"/>
                                <span style={{ color: "#000" }}>
                                    {" "}
                                    {/* of all  */}
                                    <Trans i18nKey="homepage.takecontrol.part3"/>
                                    <br/>
                                    {/* your payments */}
                                    <Trans i18nKey="homepage.takecontrol.part4"/>
                                </span>
                            </span>
                        </h1>
                        <br />
                    </div>
                </div>
                <div
                    className="takeControlPage-items"
                >
                    <div
                        className="takeControlPage-items-1"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: 100
                        }}
                    >
                        <Section
                            // text="Plan future expenses and recurring payments"
                            text={t("homepage.takecontrol.part5")}
                            image={netflixAirbnbCard}
                        />
                        <Section
                            // text="Smart tips help you optimize your finances, and major expenses"
                            text={t("homepage.takecontrol.part6")}
                            image={smartTipsCard}
                        />
                    </div>
                    <MediaQuery minWidth={500}>
                        <div
                            className="homepage.takeControlPage-items-1"
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <Section
                                // text="Transfer money between all your bank accounts from one place"
                                text={t("homepage.takecontrol.part7")}
                                image={transferMoneyCard}
                            />
                            <Section
                                // text="Your payment history from all your accounts, in one overview"
                                text={t("homepage.takecontrol.part8")}
                                image={paymentHistoryCard}
                            />
                        </div>
                    </MediaQuery>
                </div>
            </div>
        </div>
    );
}

const Section: React.FC<{ text: string; image: string }> = props => {
	return (
		<div style={{ textAlign: "left" }}>
			<div className="control-section-text" style={{ paddingBottom: 60, width: "75%" }}>{props.text}</div>
			<div style={{ paddingLeft: "20%" }}>
				<img src={props.image} alt="preview card" width="100%" />
			</div>
		</div>
	);
};

export default TakeControlPageFunction;