import React, { useState, useEffect } from 'react'
import MediaQuery from 'react-responsive'
import styled, { keyframes } from 'styled-components'
import EmailElement from './EmailElement'
import { Trans } from 'react-i18next'

import arrow from '../images/fancy_arrow_up.svg'
import phoneAssetsImg from '../images/assets_phone.svg'
import blackLineChart from '../images/black_line_chart.svg'
// import appStoreImg from "../images/app_store.svg";
// import playStoreImg from "../images/play_store.svg";

import { FaInstagram } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'

import '../App.css'

const TakeCarePageFunction = (
    refObj: React.RefObject<HTMLDivElement>,
    isVisible: boolean
) => {
    const [height, setHeight] = useState<number>(0)
    const [animatedArrows, setAnimatedArrows] = useState<any>([])

    const createAnimatedIcon = (
        startY: number,
        endY: number,
        x: number,
        duration: number,
        icon: string,
        radius?: number
    ) => {
        radius = radius || 50
        const yKf = keyframes`
        from {
            transform: translateY(${endY}}px);
        }
    
        to {
            transform: translateY(${endY - startY}px);
        }
    `
        const opacityKf = keyframes`
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
        `
        const AnimatedContainer = styled.div`
            position: absolute;
            left: ${x}px;
            top: ${startY}px;
            opacity: 0;
            -webkit-animation: ${yKf} ${duration}ms ease-out,
                ${opacityKf} ${duration * 1.25}ms ease-out;

            animation: ${yKf} ${duration}ms ease-out,
                ${opacityKf} ${duration * 1.25}ms ease-out;
            will-change: transform, opacity;
        `
        return (
            <AnimatedContainer key={AnimatedContainer.toString()}>
                <img
                    src={icon}
                    alt="Animated arrow"
                    width={radius}
                    height={radius}
                />
            </AnimatedContainer>
        )
    }

    // Executed once after the component was initialized, similar to componentDidMount() in class-based components.
    useEffect(() => {
        const page = document.getElementById('take-care')

        if (page) {
            const newHeight = page.clientHeight
            setHeight(newHeight)
            const newAnimatedArrows = [
                createAnimatedIcon(
                    (height + 1200) * 0.97,
                    50,
                    (window.innerWidth - 50) * 0.15,
                    14000,
                    arrow,
                    70
                ),
                createAnimatedIcon(
                    (height + 1200) * 0.87,
                    90,
                    (window.innerWidth - 50) * 0.9,
                    11000,
                    arrow
                ),
                createAnimatedIcon(
                    (height + 1200) * 0.66,
                    120,
                    (window.innerWidth - 50) * 0.6,
                    15000,
                    arrow,
                    45
                ),
                createAnimatedIcon(
                    (height + 1200) * 0.81,
                    120,
                    (window.innerWidth - 50) * 0.42,
                    21000,
                    arrow
                ),
                createAnimatedIcon(
                    (height + 1200) * 0.73,
                    70,
                    (window.innerWidth - 50) * 0.3,
                    16000,
                    arrow,
                    60
                ),
                createAnimatedIcon(
                    (height + 1200) * 0.83,
                    70,
                    (window.innerWidth - 50) * 0.73,
                    25000,
                    arrow,
                    60
                ),
            ]
            setAnimatedArrows(newAnimatedArrows)
        }
    }, [])

    return (
        <div ref={refObj} style={{ position: 'absolute', height: '100%' }}>
            <div style={{ position: 'relative', width: '100vw' }}>
                <div
                    id="take-care"
                    style={{
                        backgroundColor: '#fff',
                        position: 'absolute',
                    }}
                >
                    <div style={{ padding: '50px 0 50px 0' }}>
                        <div
                            style={{
                                paddingLeft: '5vw',
                            }}
                        >
                            <h2
                                className="App-header Small-top-header-style"
                                style={{ textAlign: 'left' }}
                            >
                                {/* Track your long-term planning */}
                                <Trans i18nKey="homepage.takecare.part1" />
                            </h2>
                            <br />
                            <MediaQuery minWidth="930px">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <h1
                                        className="App-header"
                                        style={{
                                            alignItems: 'start',
                                            width: '40vw',
                                            textAlign: 'left',
                                        }}
                                    >
                                        <span style={{ color: '#6432FF' }}>
                                            {/* Take care */}
                                            <Trans i18nKey="homepage.takecare.part2" />
                                            <br />
                                            <span style={{ color: '#000' }}>
                                                {' '}
                                                {/* of your financial future */}
                                                <Trans i18nKey="homepage.takecare.part3" />
                                            </span>
                                        </span>
                                    </h1>
                                    <div className="thicc-text">
                                        <br />
                                        <span>01</span>
                                        {/* Set goals in saving pots */}
                                        <Trans i18nKey="homepage.takecare.part4" />
                                        <br />
                                        <br />
                                        <span>02</span>
                                        {/* Track your pension and investment accounts */}
                                        <Trans i18nKey="homepage.takecare.part5" />
                                        <br />
                                        <br />
                                        <span>03</span>
                                        {/* Create an optimized financial plan */}
                                        <Trans i18nKey="homepage.takecare.part6" />
                                        <br />
                                    </div>
                                </div>
                            </MediaQuery>
                            <MediaQuery maxWidth="929px">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <h1
                                        className="App-header"
                                        style={{
                                            alignItems: 'start',
                                            textAlign: 'start',
                                        }}
                                    >
                                        <span>
                                            {/* Take care */}
                                            <Trans i18nKey="homepage.takecare.part2" />
                                            <span style={{ color: '#fff' }}>
                                                {' '}
                                                {/* of your financial future */}
                                                <Trans i18nKey="homepage.takecare.part3" />
                                            </span>
                                        </span>
                                    </h1>
                                    <div className="thicc-text">
                                        <br />
                                        <span>01</span>
                                        {/* Set goals in saving pots */}
                                        <Trans i18nKey="homepage.takecare.part4" />
                                        <br />
                                        <br />
                                        <span>02</span>
                                        {/* Track your pension and investment accounts */}
                                        <Trans i18nKey="homepage.takecare.part5" />
                                        <br />
                                        <br />
                                        <span>03</span>
                                        {/* Create an optimized financial plan */}
                                        <Trans i18nKey="homepage.takecare.part6" />
                                        <br />
                                    </div>
                                </div>
                            </MediaQuery>
                            <br />
                        </div>
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '85vh',
                        }}
                    >
                        <div style={{ textAlign: 'center', height: '75vh' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    margin: '0 auto',
                                    left: 0,
                                    width: '100vw',
                                    padding: '0 2.5vw',
                                    zIndex: 2,
                                }}
                            >
                                <img
                                    src={blackLineChart}
                                    alt="assets page preview"
                                    width="100%"
                                />
                            </div>
                            <img
                                style={{
                                    zIndex: 3,
                                    position: 'relative',
                                }}
                                src={phoneAssetsImg}
                                alt="assets page preview"
                                height="100%"
                            />
                        </div>
                    </div>
                    {
                        //Email
                    }
                    <div
                        style={{
                            height: '10vh',
                        }}
                    >
                        <div
                            style={{
                                width: '100vw',
                                height: '100%',
                                padding: '0 2.5vw',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <EmailElement Emailnumber={2}></EmailElement>
                        </div>
                    </div>
                    {
                        //Email
                    }
                    <div
                        style={{
                            width: '100vw',
                            height: '5vh',
                            padding: '0 2.5vw',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {/* <div style={{ backgroundColor: "#fff" }}>
									<img
										src={appStoreImg}
										alt="App Store Button"
										height="90%"
									/>
								</div>
								<div>
									<img
										src={playStoreImg}
										alt="App Store Button"
										height="90%"
									/>
								</div> */}
                        </div>
                        <MediaQuery minWidth={500}>
                            <div
                                style={{
                                    margin: 'auto 0',
                                }}
                            >
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        color: '#141e37',
                                        paddingRight: '30px',
                                    }}
                                    href="https://www.facebook.com/Halff-334931950765895/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaFacebook size="32px" />
                                </a>
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        color: '#141e37',
                                        paddingRight: '30px',
                                    }}
                                    href="https://www.linkedin.com/company/halffapp/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLinkedin size="32px" />
                                </a>
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        color: '#141e37',
                                    }}
                                    href="https://www.instagram.com/halff_app"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaInstagram size="32px" />
                                </a>
                            </div>
                        </MediaQuery>
                    </div>
                    <MediaQuery maxWidth={499}>
                        <div
                            style={{
                                margin: 'auto 0',
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            <a
                                style={{
                                    textDecoration: 'none',
                                    color: '#141e37',
                                    paddingRight: '20px',
                                }}
                                href="https://www.facebook.com/Halff-334931950765895/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaFacebook size="30px" />
                            </a>
                            <a
                                style={{
                                    textDecoration: 'none',
                                    color: '#141e37',
                                    paddingRight: '20px',
                                }}
                                href="https://www.linkedin.com/company/halffapp/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaLinkedin size="30px" />
                            </a>
                            <a
                                style={{
                                    textDecoration: 'none',
                                    color: '#141e37',
                                }}
                                href="https://www.instagram.com/halff_app"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaInstagram size="30px" />
                            </a>
                        </div>
                    </MediaQuery>
                    <div
                        style={{
                            margin: 'auto 0',
                            color: '#141e37',
                            textAlign: 'center',
                            padding: '25px 0',
                        }}
                    >
                        This website is developed with the support of:
                    </div>

                    <div
                        style={{
                            margin: 'auto 0',
                            color: '#141e37',
                            textAlign: 'center',
                            padding: '4px 0',
                        }}
                    >
                        <a
                            href="https://www.flandersinvestmentandtrade.com/en"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={require('../images/fitlogoscale.png')} />
                        </a>
                    </div>
                    <div
                        style={{
                            color: '#000',
                            textAlign: 'center',
                            padding: '25px 0',
                        }}
                    >
                        © Copyright 2020 Halff.
                    </div>
                </div>
                <MediaQuery minWidth={930}>
                    {isVisible ? animatedArrows : []}
                </MediaQuery>
            </div>
        </div>
    )
}

export default TakeCarePageFunction
