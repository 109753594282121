import React, { FC } from 'react'
import MediaQuery from 'react-responsive'
import EmailElement from './EmailElement'
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import jorisImage from '../images/joris.jpg'
import wesleyImage from '../images/wesley.jpg'
import basImage from '../images/bas.jpg'
import vincentImage from '../images/vincent.jpg'
import jenteImage from '../images/jente.jpg'
import jonaImage from '../images/jona.jpg'
import kristofImage from '../images/kristof.jpg'
import thomasImage from '../images/thomas.jpg'
import soufyanImage from '../images/soufyan.jpg'
import kobeImage from '../images/kobe.jpg'
import bramImage from '../images/bram.png'
import shouryaImage from '../images/shourya.jpg'
import enriqueImage from '../images/enrique.jpg'

import { Trans } from 'react-i18next'
import '../App.css'

interface Props {
    // refObj: React.RefObject<HTMLDivElement>
}

//const TeamPageFunction extends React.Component<{refObj: React.RefObject<HTMLDivElement>;}> {
const TeamPageFunction: FC<Props> = ({}): JSX.Element => {
    return (
        //<div ref={refObj}>
        <div>
            <div className="content">
                <div style={{ backgroundColor: 'rgb(250,252,253)' }}>
                    <div
                        style={{
                            backgroundColor: '#fff',
                        }}
                    ></div>
                    <div className="box">
                        <div className="row">
                            <h2 className="teamheader">
                                {/* Our Team */}
                                <Trans i18nKey="teampage.ourteam" />
                            </h2>
                            <div className="column">
                                <a
                                    href="https://www.linkedin.com/in/jona-halffman-178a8099/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        // src="https://media-exp1.licdn.com/dms/image/C5603AQH0ubXLZSB-_w/profile-displayphoto-shrink_200_200/0?e=1593648000&v=beta&t=_O5ADINszIJuKIl1gk2QxZeW8pA13DOGVX9uDNEHzY0"
                                        src={jonaImage}
                                        alt="Profile"
                                    ></img>
                                    <h2>
                                        Jona
                                        <br />
                                        Halffman
                                    </h2>
                                    <p>CEO & Co-Founder</p>
                                </a>
                            </div>
                            <div className="column">
                                <a
                                    href="https://www.linkedin.com/in/soufyan-hadra-aaaa5319a/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        // src="https://media-exp1.licdn.com/dms/image/C5103AQFequmyJoOehg/profile-displayphoto-shrink_200_200/0?e=1593648000&v=beta&t=jgVkwKQX7d3Pw8m1KL3jSHpV_wlTXBN5uyTN9Jj4uEY"
                                        src={soufyanImage}
                                        alt="Profile"
                                    ></img>
                                    <h2>
                                        Soufyan
                                        <br />
                                        Hadra
                                    </h2>
                                    <p>CMO & Co-Founder</p>
                                </a>
                            </div>
                            <div className="column">
                                <a
                                    href="https://www.linkedin.com/in/wesley-polling-394a6538/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        // src="https://media-exp1.licdn.com/dms/image/C4E03AQGoj3PXp3weFQ/profile-displayphoto-shrink_200_200/0?e=1593648000&v=beta&t=3RfZOcxNY782qN3QdObKPfu3jLBn7D7kfpsYewFNxBA"
                                        src={wesleyImage}
                                        alt="Profile"
                                    ></img>
                                    <h2>
                                        Wesley
                                        <br />
                                        Polling
                                    </h2>
                                    <p>BD & Co-Founder</p>
                                </a>
                            </div>
                            <div className="column">
                                <a
                                    href="https://www.linkedin.com/in/bram-vanbilsen/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={bramImage}
                                        //src="https://media-exp1.licdn.com/dms/image/C4D03AQF1q2HBraoePg/profile-displayphoto-shrink_200_200/0?e=1593648000&v=beta&t=aT5au32HVzeD6088UU5MJHyR4y-QyVoQ5B3ST_R_Fes"
                                        alt="Profile"
                                    ></img>
                                    <h2>
                                        Bram
                                        <br />
                                        Vanbilsen
                                    </h2>
                                    <p>Full-stack Developer</p>
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <a
                                    href="https://www.linkedin.com/in/bastiaan-tamm/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        // src="https://media-exp1.licdn.com/dms/image/C5603AQGSgOEm_0grNw/profile-displayphoto-shrink_200_200/0?e=1593648000&v=beta&t=fHXSHFbNh4CL20c7dFJAjK0BGgmdKT2Rx_cdi-eUvkE"
                                        src={basImage}
                                        alt="Profile"
                                    ></img>
                                    <h2>
                                        Bastian
                                        <br />
                                        Tamm
                                    </h2>
                                    <p>Full-stack Developer</p>
                                </a>
                            </div>
                            <div className="column">
                                <a
                                    href="https://www.linkedin.com/in/enrique-dehaerne-5a283913a/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={enriqueImage}
                                        // src="https://media-exp1.licdn.com/dms/image/C5603AQFMkGd1NA7Zcg/profile-displayphoto-shrink_200_200/0?e=1593648000&v=beta&t=i_vXshbvfxirCPAhCHRfWgH_GlTyE2pb8ATiAcpz9NE"
                                        alt="Profile"
                                    ></img>
                                    <h2>
                                        Enrique
                                        <br />
                                        Dehaerne
                                    </h2>
                                    <p>Full-stack Developer</p>
                                </a>
                            </div>
                            <div className="column">
                                <a
                                    href="https://www.linkedin.com/in/joris-ignoul-41432b18a/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={jorisImage} alt="Profile"></img>
                                    <h2>
                                        Joris
                                        <br />
                                        Ignoul
                                    </h2>
                                    <p>Full-stack Developer</p>
                                </a>
                            </div>
                            <div className="column2">
                                <a
                                    href="https://www.linkedin.com/in/kobe-reygel-8889431b2/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={kobeImage} alt="Profile"></img>
                                    <h2>
                                        Kobe
                                        <br />
                                        Reygel
                                    </h2>
                                    <p>Full-stack Developer</p>
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column2">
                                <a
                                    href="https://www.linkedin.com/in/shourya-bhattacharya-0b2237104/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        //src="https://media-exp1.licdn.com/dms/image/C5103AQG_FV2luwwbbQ/profile-displayphoto-shrink_200_200/0?e=1593648000&v=beta&t=HbIFTdxkjfvl9CQNCFl58e7ZCUXNPU1LuQJTTtynuJE"
                                        src={shouryaImage}
                                        alt="Profile"
                                    ></img>
                                    <h2>
                                        Shourya
                                        <br />
                                        Bhattacharya
                                    </h2>
                                    <p>AI Developer Intern</p>
                                </a>
                            </div>
                            <div className="column2">
                                <a
                                    href="https://www.linkedin.com/in/thomasdheer/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={thomasImage} alt="Profile"></img>
                                    <h2>
                                        Thomas
                                        <br />
                                        D'heer
                                    </h2>
                                    <p>Full-stack Developer</p>
                                </a>
                            </div>
                            <div className="column2">
                                <a
                                    href="https://www.linkedin.com/in/kristof-de-troch-374ba1198/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={kristofImage} alt="Profile"></img>
                                    <h2>
                                        Kristof
                                        <br />
                                        De Troch
                                    </h2>
                                    <p>Full-stack Developer</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="take-care"
                    style={{
                        backgroundColor: '#141e37',
                        position: 'absolute',
                        color: '#fff',
                        paddingTop: '25px',
                    }}
                >
                    {
                        //Email
                    }
                    <div
                        style={{
                            height: '10vh',
                        }}
                    >
                        <div
                            style={{
                                width: '100vw',
                                height: '100%',
                                padding: '0 2.5vw',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <EmailElement Emailnumber={2}></EmailElement>
                        </div>
                    </div>
                    {
                        //Email
                    }
                    <div
                        style={{
                            width: '100vw',
                            height: '5vh',
                            padding: '0 2.5vw',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        ></div>
                        <MediaQuery minWidth={500}>
                            <div
                                style={{
                                    margin: 'auto 0',
                                }}
                            >
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        color: '#fff',
                                        paddingRight: '30px',
                                    }}
                                    href="https://www.facebook.com/Halff-334931950765895/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaFacebook size="48px" />
                                </a>
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        color: '#fff',
                                        paddingRight: '30px',
                                    }}
                                    href="https://www.linkedin.com/company/halffapp/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLinkedin size="48px" />
                                </a>
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        color: '#fff',
                                    }}
                                    href="https://www.instagram.com/halff_app"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaInstagram size="48px" />
                                </a>
                            </div>
                        </MediaQuery>
                    </div>
                    <MediaQuery maxWidth={499}>
                        <div
                            style={{
                                margin: 'auto 0',
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            <a
                                style={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    paddingRight: '20px',
                                }}
                                href="https://www.facebook.com/Halff-334931950765895/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaFacebook size="30px" />
                            </a>
                            <a
                                style={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    paddingRight: '20px',
                                }}
                                href="https://www.linkedin.com/company/halffapp/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaLinkedin size="30px" />
                            </a>
                            <a
                                style={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                }}
                                href="https://www.instagram.com/halff_app"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaInstagram size="30px" />
                            </a>
                        </div>
                    </MediaQuery>
                    <div
                        style={{
                            margin: 'auto 0',
                            // color: '#141e37',
                            textAlign: 'center',
                            padding: '25px 0',
                        }}
                    >
                        This website is developed with the support of:
                    </div>

                    <div
                        style={{
                            margin: 'auto 0',
                            color: '#141e37',
                            textAlign: 'center',
                            padding: '4px 0',
                        }}
                    >
                        <a
                            href="https://www.flandersinvestmentandtrade.com/en"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {/* <img src={require('../images/fitlogo.jpg')} width="400" height="50"/> */}
                            {/* This website is developed with the support of */}
                            <img src={require('../images/fitlogoscaleinverted.png')} />
                        </a>
                    </div>
                    <div style={{ textAlign: 'center', padding: '25px 0' }}>
                        © Copyright 2020 Halff.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamPageFunction
