import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import common_en from "./translations/en/common.json";
import common_nl from "./translations/nl/common.json";
import common_fr from "./translations/fr/common.json";

// INITIALIZE I18NEXT WITH AUTOMATIC LANGUAGE DETECTION
// Translations are in the json files within the "translations" folder

const options = {
  // Order in which and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // Keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // Cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // Optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // Optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // Optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: "/", sameSite: "strict" },
};

// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
i18next.use(LanguageDetector).init({
  detection: options,
});

i18next
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  /////////.use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    resources: {
      en: {
        translation: common_en,
      },
      nl: {
        translation: common_nl,
      },
      fr: {
        translation: common_fr,
      },
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18next;
