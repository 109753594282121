import React, { useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Link } from 'react-router-dom'
import EmailElement from './EmailElement'
import useInterval from '../util/useInterval'
import { useTranslation, Trans } from 'react-i18next'
import ReactFlagsSelect from 'react-flags-select'
import i18n from 'i18next'

import logo from '../images/halffLogo.svg'
import phones from '../images/front_phones.png'
import phones1 from '../images/front_phones_1.png'
import phones2 from '../images/front_phones_2.png'
import phones3 from '../images/front_phones_3.png'

// global CSS module
import '../App.css'

//import CSS module for the flag selector, which has been adapted from: 'react-flags-select/css/react-flags-select.css'
import './FlagSelector.css'

const WelcomePageFunction = (refObj: React.RefObject<HTMLDivElement>) => {
    const [slide, setSlide] = useState<number>(0)
    const [appear, setAppear] = useState(true)

    /**
     * CSS transition function: switches between three slides of content on the smartphones on the welcome screen (every 3s)
     */

    useInterval(() => {
        if (slide === 3) {
            setSlide(0)
        } else {
            let newNumber = slide + 1
            setSlide(newNumber)
        }
    }, 3000)

    const slides = [phones, phones1, phones2, phones3]

    /**
     * more CSS transition functionss for welcomepage
     */
    const toggleAppear = () => {
        setAppear(!appear)
    }

    // Change the website-language accordingly
    const onSelectFlag = (countryCode: string) => {
        if (countryCode === 'NL') {
            i18n.changeLanguage('nl')
            window.location.reload(false)
        } else if (countryCode === 'FR') {
            i18n.changeLanguage('fr')
            window.location.reload(false)
        } else if (countryCode === 'GB') {
            i18n.changeLanguage('en')
            window.location.reload(false)
        }
    }

    // Get the current language in the correct form
    const getCurrentLanguage = () => {
        const curLan = i18n.language
        if (curLan === 'nl') {
            return 'NL'
        } else if (curLan === 'fr') {
            return 'FR'
        } else if (curLan === 'en') {
            return 'GB'
        }
    }

    return (
        <div ref={refObj}>
            <div className="App">
                <div className="Navbar">
                    <img height={40} src={logo} alt="logo" />
                    <div id="Menu">
                        <a href="/" className="current-page">
                            <Link to="/" className="MenuItemActive">
                                <Trans i18nKey="homepage.welcome.home" />
                            </Link>
                        </a>
                        <a href="/about">
                            <Link to="/about" className="MenuItem">
                                <Trans i18nKey="homepage.welcome.careers" />
                            </Link>
                        </a>
                        <a
                            href="mailto:contact@halffapp.com"
                            className="MenuItem"
                        >
                            <Trans i18nKey="homepage.welcome.contact" />
                        </a>
                        <ReactFlagsSelect
                            className="MenuItem"
                            defaultCountry={getCurrentLanguage()}
                            countries={['NL', 'FR', 'GB']}
                            customLabels={{
                                NL: 'NL',
                                GB: 'EN',
                                FR: 'FR',
                            }}
                            onSelect={onSelectFlag}
                        />
                    </div>
                </div>
                <div
                    style={{
                        height: 'calc(100% - 40px - 80px)',
                        display: 'flex',
                        paddingTop: 5,
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <br />
                    <h2 className="App-header Small-top-header-style">
                        {/* Become your own financial advisor */}
                        <Trans i18nKey="homepage.welcome.finadv" />
                    </h2>
                    <br />
                    <h1 className="App-header">
                        {/* Introducing the next generation */}
                        <Trans i18nKey="homepage.welcome.introducing" />
                        {/* of mobile banking */}
                        <span className="Purple">
                            {' '}
                            <Trans i18nKey="homepage.welcome.mobilebanking" />
                        </span>
                    </h1>
                    <br />
                    <EmailElement Emailnumber={1}></EmailElement>
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}
                    >
                        <CSSTransition
                            in={appear}
                            appear={true}
                            timeout={600}
                            classNames="fade"
                        >
                            <TransitionGroup
                                className="phones-container"
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    height: '100%',
                                    width: '100%',
                                    overflow: 'hidden',
                                }}
                            >
                                <CSSTransition
                                    timeout={600}
                                    classNames="fade"
                                    key={slide}
                                >
                                    <img
                                        style={{}}
                                        className="PhoneFrontImg"
                                        src={slides[slide]}
                                        alt="App preview"
                                    />
                                </CSSTransition>
                            </TransitionGroup>
                        </CSSTransition>
                    </div>
                </div>
            </div>
            <div className="section1-background"></div>
        </div>
    )
}

export default WelcomePageFunction
