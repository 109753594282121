import React from 'react'
import MediaQuery from 'react-responsive'

import dashboardPhone from '../images/dashboard_phone.png'
import dashboardStart from '../images/dashboard_phone_start.png'
import dashboardEnd from '../images/dashboard_phone_end.png'
import dashboardContent from '../images/dashboard_content.png'
import { Trans } from 'react-i18next'

import '../App.css'

const DashboardPageFunction = (
    refObj: React.RefObject<HTMLDivElement>,
    contentTop: number,
    textPosition: number,
    isInFocus: boolean
) => {
    const calculatePhoneHeight = (widthVW: number) => {
        return ((widthVW / 100) * window.innerWidth) / 0.5
    }

    const calculateContentHeight = (widthVW: number) => {
        return ((widthVW / 100) * window.innerWidth) / 0.36
    }

    const calculateContentScrollHeight = (widthVW: number) => {
        return calculateContentHeight(widthVW) * 0.14
    }

    const calculateContentTop = (progression: number, imgWidthVW: number) => {
        const contentHeight = calculateContentHeight(imgWidthVW)
        const contentScrollHeight = calculateContentScrollHeight(imgWidthVW)
        return (
            (contentHeight * progression - contentHeight) /
            (contentHeight / 1.8 / contentScrollHeight)
        )
    }

    const imgWidthVW = 20
    contentTop = calculateContentTop(textPosition / 100, imgWidthVW)

    const phoneHeight = calculatePhoneHeight(imgWidthVW)
    const topSpacing = (window.innerHeight - phoneHeight) / 2
    const contentScrollHeight = calculateContentScrollHeight(imgWidthVW)

    // +5 pixels of insurance
    const clipTop = -contentTop + 5
    const clipBottom = (textPosition / 100) * contentScrollHeight * 2

    return (
        <div ref={refObj}>
            <MediaQuery minWidth={930}>
                <div
                    style={{
                        position: isInFocus ? 'relative' : 'initial',
                        height: '200vh',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: isInFocus ? 'inherit' : 'none',
                            height: '100vh',
                            width: '100vw',
                            position: 'fixed',
                            zIndex: 1,
                            top: 0,
                            left: 0,
                            willChange: 'top',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: '100%',
                                }}
                            >
                                <div
                                    className="dashboard-text"
                                    style={{
                                        height: phoneHeight,
                                        width: '35%',
                                        padding: `0px 5px 0 20px`,
                                        marginTop: topSpacing,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: `${textPosition}%`,
                                        }}
                                    />
                                    <div>
                                        {/* Halff automates financial management,
                                        and finds opportunities that save you
                                        money. */}
                                        <Trans i18nKey="homepage.dashboard.part1" />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: imgWidthVW + 'vw',
                                        paddingTop: topSpacing,
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '100%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                zIndex: 100,
                                                position: 'absolute',
                                            }}
                                        >
                                            <img
                                                src={dashboardPhone}
                                                alt="ok"
                                                style={{
                                                    width: imgWidthVW + 'vw',
                                                }}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: contentTop,
                                                clipPath: `inset(${clipTop}px 0px ${clipBottom}px 0px)`,
                                                WebkitClipPath: `inset(${clipTop}px 0px ${clipBottom}px 0px)`,
                                                willChange: 'top',
                                            }}
                                        >
                                            <img
                                                src={dashboardContent}
                                                alt="ok"
                                                style={{
                                                    width: imgWidthVW + 'vw',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            height: '100vh',
                            display: isInFocus ? 'none' : 'inherit',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: '100%',
                                }}
                            >
                                <div
                                    className="dashboard-text"
                                    style={{
                                        height: phoneHeight,
                                        width: '35%',
                                        padding: `0px 5px 0 20px`,
                                        marginTop: topSpacing,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: `${textPosition}%`,
                                        }}
                                    />
                                    <div>
                                        {/* Halff automates financial management,
                                        and finds opportunities that save you
                                        money. */}
                                        <Trans i18nKey="homepage.dashboard.part1" />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: imgWidthVW + 'vw',
                                        paddingTop: topSpacing,
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '100%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                zIndex: 100,
                                                position: 'absolute',
                                            }}
                                        >
                                            <img
                                                src={dashboardStart}
                                                alt="ok"
                                                style={{
                                                    width: imgWidthVW + 'vw',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            height: '100vh',
                            display: isInFocus ? 'none' : 'inherit',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: '100%',
                                }}
                            >
                                <div
                                    className="dashboard-text"
                                    style={{
                                        height: phoneHeight,
                                        width: '35%',
                                        padding: `0px 5px 0 20px`,
                                        marginTop: topSpacing,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: `${textPosition}%`,
                                        }}
                                    />
                                    <div>
                                        {/* Halff automates financial management,
                                        and finds opportunities that save you
                                        money. */}
                                        <Trans i18nKey="homepage.dashboard.part1" />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: imgWidthVW + 'vw',
                                        paddingTop: topSpacing,
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '100%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                zIndex: 100,
                                                position: 'absolute',
                                            }}
                                        >
                                            <img
                                                src={dashboardEnd}
                                                alt="ok"
                                                style={{
                                                    width: imgWidthVW + 'vw',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={929}>
                <div
                    style={{
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <img
                            src={dashboardStart}
                            style={{
                                display: 'block',
                                margin: 'auto',
                                maxWidth: '50%',
                            }}
                            alt="App dashboard display"
                        />
                    </div>
                    <div
                        className="dashboard-text"
                        style={{
                            width: '75%',
                            paddingTop: 25,
                            textAlign: 'center',
                        }}
                    >
                        {/* Halff automates financial management, and finds
                        opportunities that save you money. */}
                        <Trans i18nKey="homepage.dashboard.part1" />
                    </div>
                </div>
            </MediaQuery>
            <div className="section2-background" />
        </div>
    )
    // }
}

export default DashboardPageFunction;