import React, { useState, useEffect, FC, useRef } from 'react'
import WelcomePageFunction from './WelcomePageFunction'
import DashboardPageFunction from './DashboardPageFunction'
import CardStackPageFunction from "./CardStackPageFunction";
import FloatingIconsPageFunction from './FloatingIconsPageFunction';
import TakeControlPageFunction from "./TakeControlPageFunction";
import TakeCarePageFunction from "./TakeCarePageFunction";
import { Helmet } from 'react-helmet'

import '../App.css'

interface Props {}

const HomePageFunction: FC<Props> = ({}): JSX.Element => {
    const [dashboardPageTextPosition, setDashboardPageTextPosition] = useState<
        number
    >(1000)
    const [dashboardPageTop, setDashboardPageTop] = useState<number>(0)
    const [dashboardPageIsInFocus, setDashboardPageIsInFocus] = useState<
        boolean
    >(false)
    const [cardStackPageIsVisible, setCardStackPageIsVisible] = useState<
        boolean
    >(false)
    const [
        floatingIconsPageIsVisible,
        setFloatingIconsPageIsVisible,
    ] = useState<boolean>(false)
    const [takeCarePageIsVisible, setTakeCarePageIsVisible] = useState<boolean>(
        false
    )

    const [welcomePageRef, setWelcomePageRef] = useState(
        React.createRef<HTMLDivElement>()
    )
    const [dashboardPageRef, setDashboardPageRef] = useState(
        React.createRef<HTMLDivElement>()
    )
    const [cardStackPageRef, setCardStackPageRef] = useState(
        React.createRef<HTMLDivElement>()
    )
    const [floatingIconsPageRef, setFloatingIconsPageRef] = useState(
        React.createRef<HTMLDivElement>()
    )
    const [takeCarePageRef, setTakeCareRef] = useState(
        React.createRef<HTMLDivElement>()
    )
    const [takeControlPageRef, setTakeControlPageRef] = useState(
        React.createRef<HTMLDivElement>()
    )

    const scrollTo = (y: number) => {
        window.scrollTo({
            top: y,
            left: 0,
            behavior: 'smooth',
        })
    }

    enum Page {
        Welcome = 'Welcome',
        Dashboard = 'Dashboard',
        CardStack = 'Card Stack',
        FloatingIcons = 'Floating Icons',
        TakeControl = 'Take Control',
        TakeCare = 'Take Care',
    }

    enum Direction {
        Up,
        Down,
        Left,
        Right,
    }

    function clamp(val: number, min: number, max: number): number {
        return Math.min(Math.max(val, min), max)
    }

    const header = () => {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content="Bringing financial advice to everyone"
                />
                <meta property="og:url" content="https://halffapp.com" />
                <meta
                    name="description"
                    content="
  A financial advisor right inside your banking app.
  Manage the day-to-day with budgets and an intuitive overview.
  Create long-term plans to save efficiently, and receive financial advice to optimize finances.
  "
                />
                <title>Halff | Mobile banking</title>
                <link
                    rel="icon"
                    type="image/png"
                    href="favicon.ico"
                    sizes="16x16"
                />
                <link rel="apple-touch-icon" href="favicon.ico"></link>
            </Helmet>
        )
    }

    const getCurrentPage = (
        page1Y: number,
        page2Y: number,
        page3Y: number,
        page4Y: number,
        page5Y: number,
        page6Y: number
    ): Page => {
        const currentY = window.scrollY
        if (currentY < page2Y) {
            return Page.Welcome
        } else if (currentY < page3Y) {
            return Page.Dashboard
        } else if (currentY < page4Y) {
            return Page.CardStack
        } else if (currentY < page5Y) {
            return Page.FloatingIcons
        } else if (currentY < page6Y) {
            return Page.TakeControl
        } else {
            return Page.TakeCare
        }
    }

    /**
     * Gets the pixels scrolled on a single page.
     * Thus if a page is 200 pixels in height and 20 pixels have been scrolled either
     * visually or statically, the page scroll distance will be 220.
     */
    const getPageScrollDistance = (
        topY: number,
        height: number,
        prevScrollY: number,
        prevDistance: number,
        scrollDirection: Direction
    ) => {
        if (scrollDirection === Direction.Up) {
            if (prevDistance <= 0) {
                return 0
            }
            return prevDistance - (prevScrollY - window.scrollY)
        } else {
            const newDistance = prevDistance + (window.scrollY - prevScrollY)
            if (newDistance > height) {
                return height - topY
            }
            return newDistance
        }
    }

    /**
     * Determines whether or not the screen should scroll to the DashboardPage, based on various variables.
     * @param topY 
     * @param welcomePageScrollDistance 
     * @param scrollDirection 
     * @param isNotScrollingToPage 
     */
    const shouldScrollToDashboardPage = (
        topY: number,
        welcomePageScrollDistance: number,
        scrollDirection: Direction,
        isNotScrollingToPage: boolean
    ) => {
        return (
            welcomePageScrollDistance > topY * 0.1 &&
            window.scrollY <= topY &&
            isNotScrollingToPage &&
            scrollDirection === Direction.Down &&
            window.innerWidth > 930
        )
    }

    // The variable "prevScrollY" is defined with useRef: this ensures its value is never lost when useEffect below refreshes.
    // If not, the scrolling on the page will be disrupted because "prevScrollY" resets to e.g. 0, making React think we are at the top of the screen.
    // In short, this ensures fluidity.

    let prevScrollY = useRef(0);
    let [welcomePageScrollDistance, dashboardPageScrollDistance] = Array(2).fill(0);
    let scrollingTo: Page | undefined

    // Executed every time the state variable dashboardPageIsInFocus changes.
    // This is coded in such a fashion because the scroll-event-listener must be re-initialized when this state variable changes value.
    // It does not detect it automatically.

    // What this scroll-listener does, is handle automatic scrolling where applicable, and detect which component should be in focus.
    // e.g. when the dashboardPage is in focus, the "phone" remains stationary on the screen and its content is being scrolled through.

    useEffect(() => {
        window.onscroll = (e: Event) => {
            // ---------- Check if refs are mounted ---------- //
            if (
                !welcomePageRef.current ||
                !dashboardPageRef.current ||
                !cardStackPageRef.current ||
                !floatingIconsPageRef.current ||
                !takeControlPageRef.current ||
                !takeCarePageRef.current
            ) {
                return
            }
            const [
                welcomePageY,
                dashboardPageY,
                cardStackPageY,
                floatingIconsPageY,
                takeControlPageY,
                takeCarePageY,
            ] = [
                0,
                dashboardPageRef.current.offsetTop,
                cardStackPageRef.current.offsetTop,
                floatingIconsPageRef.current.offsetTop,
                takeControlPageRef.current.offsetTop,
                takeCarePageRef.current.offsetTop,
            ]
            const currentPage = getCurrentPage(
                welcomePageY,
                dashboardPageY,
                cardStackPageY,
                floatingIconsPageY,
                takeControlPageY,
                takeCarePageY
            )

            // ---------- Check scroll direction and position ---------- //
            const scrollDirection: Direction =
                prevScrollY.current > window.scrollY ? Direction.Up : Direction.Down

            // ---------- Welcome Page ---------- //
            welcomePageScrollDistance = getPageScrollDistance(
                welcomePageY,
                welcomePageRef.current.clientHeight,
                prevScrollY.current,
                welcomePageScrollDistance,
                scrollDirection
            )

            // ---------- Dashboard Page ---------- //

            if (currentPage === Page.Dashboard) {
                dashboardPageScrollDistance =
                    window.scrollY - window.innerHeight
                setDashboardPageTextPosition(
                    100 -
                        (dashboardPageScrollDistance /
                            dashboardPageRef.current.clientHeight) *
                            200
                )
                setDashboardPageTop(
                    clamp(dashboardPageScrollDistance, 0, window.innerHeight)
                )
            }

            if (
                window.scrollY >= welcomePageRef.current.clientHeight &&
                window.scrollY <= dashboardPageRef.current.clientHeight
            ) {
                const newBool = true
                setDashboardPageIsInFocus(newBool)
            } else if (dashboardPageIsInFocus === true) {
                setDashboardPageIsInFocus(false)
            }

            if (
                shouldScrollToDashboardPage(
                    dashboardPageY,
                    welcomePageScrollDistance,
                    scrollDirection,
                    !scrollingTo
                )
            ) {
                scrollingTo = Page.Dashboard
                scrollTo(dashboardPageY)
                const scrollListener = () => {
                    if (window.scrollY === dashboardPageY) {
                        scrollingTo = undefined
                        window.removeEventListener('scroll', scrollListener)
                    }
                }
                window.addEventListener('scroll', scrollListener)
            }

            // ---------- Card stack Page ---------- //
            if (
                window.scrollY >= dashboardPageY &&
                window.scrollY <=
                    cardStackPageRef.current.clientHeight + cardStackPageY
            ) {
                setCardStackPageIsVisible(true)
            }

            // ---------- Floating Icons Page ---------- //
            if (
                window.scrollY >= cardStackPageY &&
                window.scrollY <=
                    floatingIconsPageRef.current.clientHeight +
                        floatingIconsPageY
            ) {
                setFloatingIconsPageIsVisible(true)
            }

            // ---------- Take Care Page ---------- //
            if (
                window.scrollY >= takeControlPageY &&
                window.scrollY <=
                    takeCarePageRef.current.clientHeight + takeCarePageY
            ) {
                setTakeCarePageIsVisible(true)
            }

            // Update the Y-position of the window in the ref-value "prevScrollY" after every scroll
            prevScrollY.current = window.scrollY
        }
    }, [
        dashboardPageIsInFocus,
    ])

    return (
        <div>
            {header()}
            {WelcomePageFunction(welcomePageRef)}
            {DashboardPageFunction(
                dashboardPageRef,
                dashboardPageTop,
                dashboardPageTextPosition,
                dashboardPageIsInFocus
            )}
            {CardStackPageFunction(cardStackPageRef, cardStackPageIsVisible)}
            {FloatingIconsPageFunction(floatingIconsPageRef, floatingIconsPageIsVisible)}
            {TakeControlPageFunction(takeControlPageRef)}
            {TakeCarePageFunction(takeCarePageRef, takeCarePageIsVisible)}
        </div>
    )
}

export default HomePageFunction
