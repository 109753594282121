import React, {useEffect, useRef} from "react";
//import bankCards from "../images/bankCards.svg";
import bankcard1 from "../images/Group1.png"
import bankcard2 from "../images/Group2.png"
import bankcard3 from "../images/Group3.png"
import styled, { keyframes } from "styled-components";
import { Trans } from 'react-i18next';

import "../App.css";

const CardStackPage = (
	refObj: React.RefObject<HTMLDivElement>, isVisible: boolean) =>
{
    const floatingCard = (
		startY: number,
		endY: number,
		zIndex: number,
		image : any
	) => {
		const float = keyframes`
			from {
				transform: translateY(0px);
			}

			to{
				transform: translateY(${endY-startY}px);
			}
	
		`;

		const AimatedImage = styled.div`
			-webkit-animation: ${float} 6s ease-out;
			animation: ${float} 6s ease-out;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			animation-delay: 1000ms;
			-webkit-animation-delay: 1000ms;
			height: 45vh;
			position: absolute;
			top: ${startY}px;
			z-index: ${zIndex};
			will-change: transform, filter, top;
		`;

		return(
			<AimatedImage className="bankCardImgParent"  key={AimatedImage.toString()}>
				<img
					className="bankCardImg"
					src={image}
					height="130%"
					alt="bankcard"
				/>
			</AimatedImage>
		);
	}

	let animatedCards = useRef<Array<JSX.Element> | undefined>();

	useEffect(() => {
        animatedCards.current = [
            floatingCard(
                -170,
                -38,
                1000,
                bankcard1
            ),
            floatingCard(
                -80,
                -14,
                999,
                bankcard2
            ),
            floatingCard(
                10,
                10,
                998,
                bankcard3
            ),
        ];
    }, []);
			
	
//https://stackoverflow.com/questions/3186688/drop-shadow-for-png-image-in-css

	//const titleHeight = "35vh";
    const imgHeight = "45vh";
    const subTextHeight = "20vh";
    return (
        <div ref={refObj}>
            <div className="App cardStackPage" style={{ backgroundColor: "#141e37" }}>
                <div className="cardStackPage-title">
                    <div
                        style={{ width: "100%", padding: "50px 0 50px 0" }}
                    >
                        <h2
                            className="App-header Small-top-header-style"
                            style={{ color: "white" }}
                        >
                            <Trans i18nKey="homepage.cardstack.part1" />
                        </h2>
                        <br />
                        <h1
                            className="App-header"
                            style={{ color: "white" }}
                        >
                            <Trans i18nKey="homepage.cardstack.part2" />
                            <span className="Purple"> <Trans i18nKey="homepage.cardstack.part3" /></span>
                        </h1>
                        <br />
                    </div>
                </div>
                <div className="center">
                <div id="cardDiv" style={{ textAlign: "center", height: imgHeight, position: "relative" }}>
                    {(isVisible) ? animatedCards.current : null}
                </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: subTextHeight,
                        color: "#fff",
                        width: "60vw",
                        margin: "0 auto"
                    }}
                >
                    <div
                        style={{ paddingRight: 10, fontSize: 24, flex: 30 }}
                    >
                        <Trans i18nKey="homepage.cardstack.part4" />
                    </div>
                    <div style={{ paddingLeft: 10, flex: 29 }}>
                    <Trans i18nKey="homepage.cardstack.part5" />
                    </div>
                </div>
            </div>
        </div>
    );
}
// }
export default CardStackPage;
