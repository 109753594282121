import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppFunction from "./AppFunction";
import * as serviceWorker from "./serviceWorker";
 
/**
 * Importing i18n.tsx implements i18next, an internationalization-framework for JavaScript (see i18n.tsx)
 * Using translations can be done in multiple ways through the correct keyword from the translation files.
 * The following two methods are used in the code.
 * 
 * 1. t-function: access the value of a certain key
 * ------ import { useTranslation } from "react-i18next" ------
 * ------ const { t, i18n } = useTranslation() ------
 * 
 *      {t("example.key")}
 * 
 * 
 * 2. Trans-component: also allows integrating dynamic values, which are not used yet up to this point.
 *    So actually, most of the Trans components could be replaced by standard t-functions.
 * ------ import { Trans } from "react-i18next" ------
 * 
 *      <Trans i18nKey="example.key" />
 * 
 */
import './i18n';

// Polyfills
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
///////////////////////////////////////////////////

ReactDOM.render(<AppFunction />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
