import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css'

import TeamPageFunction from './pages/TeamPageFunction'
import CareersPageFunction from './pages/CareersPageFunction'
import HomePage from './pages/HomePageFunction'

const AppFunction: React.FC = () => {
    return (
        <div>
            <Router>
                <div>
                    <Route exact path="/">
                        {' '}
                        <HomePage />{' '}
                    </Route>
                    <Route path="/about">
                        <CareersPageFunction />
                    </Route>
                    <Route path="/about">
                        <TeamPageFunction />
                    </Route>
                </div>
            </Router>
        </div>
    )
}

export default AppFunction
