import React, { useRef, useEffect } from "react";
import * as ReactDOM from "react-dom";
import MediaQuery from "react-responsive";
import styled, { keyframes } from "styled-components";

import phone from "../images/transportPagePhone.svg";

import airbnb from "../images/company_logos/airbnb_round.svg";
import burgerPlace from "../images/company_logos/burgerplace_round.svg";
import costco from "../images/company_logos/costco_round.svg";
import mcDo from "../images/company_logos/mcDonalds_round.svg";
import netflix from "../images/company_logos/netflix_round.svg";
import { Trans } from "react-i18next";

import "../App.css";

const FloatingIconsPageFunction = (
	refObj: React.RefObject<HTMLDivElement>,
	isVisible: boolean) =>
{
	const createAnimatedIcon = (
		startY: number,
		endY: number,
		x: number,
		duration: number,
		icon: string,
		radius?: number
	) => {

		radius = radius || 50;
		const yKf = keyframes`
        from {
            transform: translateY(${endY}}px);
        }
  
        to {
		transform: translateY(${endY-startY}px);
        }
        `;
		const opacityKf = keyframes`
        from {
            opacity: 1;
        }

        to {
        opacity: 0;
        }
        `;
		const AnimatedContainer = styled.div`
			position: absolute;
			left: ${x}px;
			top: ${startY}px;
			opacity: 0;
			-webkit-animation: ${yKf} ${duration}ms ease-out,
				${opacityKf} ${duration * 1.25}ms ease-out;

			animation: ${yKf} ${duration}ms ease-out,
				${opacityKf} ${duration * 1.25}ms ease-out;
			will-change: transform, opacity;
		`;
		return (
			<AnimatedContainer key={AnimatedContainer.toString()}>
				<img
					src={icon}
					alt="Other Company logo"
					width={radius}
					height={radius}
				/>
			</AnimatedContainer>
		);
	}

	let animatedIcons = useRef<Array<JSX.Element> | undefined>();

    // Executed once after the component was initialized, similar to componentDidMount() in class-based components.
	useEffect(() => {
        // findDOMNode cannot be used in a functional component! but a workaround was found through the current refObject
        //const node = ReactDOM.findDOMNode();
        //const helloElement = document.getElementById("root");
        console.log('node?');
        console.log(refObj);
        
		if (refObj.current) {
            const bounds = refObj.current.getBoundingClientRect();
            const correction = 0;
			animatedIcons.current = [
				createAnimatedIcon(
					bounds.top + (window.innerHeight - 50) * 0.97 + correction,
					bounds.top + 50,
					(window.innerWidth - 50) * 0.15,
					14000,
					airbnb,
					70
				),
				createAnimatedIcon(
					bounds.top + (window.innerHeight - 50) * 0.87 + correction,
					bounds.top + 90,
					(window.innerWidth - 50) * 0.9,
					11000,
					netflix
				),
				createAnimatedIcon(
					bounds.top + (window.innerHeight - 50) * 0.66 + correction,
					bounds.top + 120,
					(window.innerWidth - 50) * 0.6,
					15000,
					costco,
					45
				),
				createAnimatedIcon(
					bounds.top + (window.innerHeight - 50) * 0.81 + correction,
					bounds.top + 120,
					(window.innerWidth - 50) * 0.42,
					21000,
					burgerPlace
				),
				createAnimatedIcon(
					bounds.top + (window.innerHeight - 50) * 0.73 + correction,
					bounds.top + 70,
					(window.innerWidth - 50) * 0.3,
					16000,
					mcDo,
					60
				)
            ];
		}
    }, []);

    return (
        <div ref={refObj}>
            <div
                style={{
                    backgroundColor: "#FCF8F0",
                    width: "100vw"
                }}
            >
                <div
                    style={{
                        padding: "100px 0px"
                    }}
                >
                    <div
                        style={{
                            paddingLeft: "5vw",
                            width: "90vw"
                        }}
                    >
                        <h2
                            className="App-header Small-top-header-style"
                            style={{ textAlign: "left" }}
                        >
                            {/* Find out how you spend money */}
                            <Trans i18nKey="homepage.floatingicons.part1" />
                        </h2>
                        <br />
                        <h1
                            className="App-header"
                            style={{ textAlign: "left" }}
                        >
                            {/* Automate your budgets and create */}
                            <Trans i18nKey="homepage.floatingicons.part2" />
                            <br/> 
                            <Trans i18nKey="homepage.floatingicons.part3" />
                            {/* new habits */}
                            <span
                                style={{
                                    color: "#6432FF"
                                }}
                            >
                                {" "}
                                {/* to reach your goals */}
                                <Trans i18nKey="homepage.floatingicons.part4" />
                            </span>
                        </h1>
                        <br />
                    </div>
                </div>
                <div style={{ textAlign: "center" }}>
                    <img
                        id="transportPhone"
                        src={phone}
                        alt="phone"
                        style={{
                            maxHeight: "100%",
                            maxWidth: "80vw"
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        padding: "50px 0"
                    }}
                >
                    <div className="dontworry"
                        style={{
                            fontSize: " 24px",
                            marginLeft: "15vw ",
                            marginRight: "8vw"
                        }}
                    >
                        {/* Stop worrying about */}
                        <Trans i18nKey="homepage.floatingicons.part5" />
                        <br />
                        <span>
                            {/* your finances */}
                            <Trans i18nKey="homepage.floatingicons.part6" />
                            </span>
                    </div>
                    <div 
                        className="dontworry-text"
                        style={{
                            fontSize: " 18px",
                            lineHeight: "133%",
                            letterSpacing: "-0.03em",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        {/* Halff helps you stay on top of what you spend money
                        on. With */}
                        <Trans i18nKey="homepage.floatingicons.part7" />
                        <span>
                            {/* the help of smart notifications to help you avoid overspending */}
                            <Trans i18nKey="homepage.floatingicons.part8" />
                        </span>
                        <span>
                            {/* and automatically detects suspicious spending trends. */}
                            <Trans i18nKey="homepage.floatingicons.part9" />
                        </span>
                    </div>
                </div>
                <MediaQuery minWidth={930}>
                    {isVisible ? animatedIcons.current : null}
                </MediaQuery>
            </div>
        </div>
    );
}

export default FloatingIconsPageFunction;