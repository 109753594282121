import React, { FC } from 'react'
import logo from '../images/halffLogo.svg'
import { Link } from 'react-router-dom'
import { FaAngleDown } from 'react-icons/fa'
import { Trans } from "react-i18next";
import ReactFlagsSelect from 'react-flags-select'
import i18n from 'i18next'

// global CSS module
import '../App.css'

//import CSS module for the flag selector, which has been adapted from: 'react-flags-select/css/react-flags-select.css'
import './FlagSelector.css'


interface Props {
    // refObj: React.RefObject<HTMLDivElement>
}

const CareersPageFunction : FC<Props> = ({}): JSX.Element => {

    // Change the website-language accordingly
    const onSelectFlag = (countryCode: string) => {
        if (countryCode === 'NL') {
            i18n.changeLanguage('nl')
            window.location.reload(false)
        } else if (countryCode === 'FR') {
            i18n.changeLanguage('fr')
            window.location.reload(false)
        } else if (countryCode === 'GB') {
            i18n.changeLanguage('en')
            window.location.reload(false)
        }
    }

    // Get the current language in the correct form
    const getCurrentLanguage = () => {
        const curLan = i18n.language
        if (curLan === 'nl') {
            return 'NL'
        } else if (curLan === 'fr') {
            return 'FR'
        } else if (curLan === 'en') {
            return 'GB'
        }
    }

    return (
        <div>
            <div className="App-careers">
                <div className="fullimg">
                    <div className="Navbar">
                        <img height={40} src={logo} alt="logo" />
                        <div id="Menu">
                            <a href="/">
                                <Link to="/" className="MenuItem">
                                    <Trans i18nKey = "careers.home" />
                                    {/* Home */}
                                </Link>
                            </a>
                            <a href="/about">
                                <Link to="/about" className="MenuItemActive">
                                    {/* Careers */}
                                    <Trans i18nKey = "careers.careers" />
                                </Link>
                            </a>
                            <a
                                href="mailto:contact@halffapp.com"
                                className="MenuItem"
                            >
                                {/* Contact */}
                                <Trans i18nKey = "careers.contact" />
                            </a>
                            <ReactFlagsSelect
                            className="MenuItem"
                            defaultCountry={getCurrentLanguage()}
                            countries={['NL', 'FR', 'GB']}
                            customLabels={{
                                NL: 'NL',
                                GB: 'EN',
                                FR: 'FR',
                            }}
                            onSelect={onSelectFlag}
                        />
                        </div>
                    </div>
                    <div
                        style={{
                            height: 'calc(100% - 40px - 80px)',
                            display: 'flex',
                            paddingTop: 5,
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div className="imagecaption">
                            <h2 className="App-header Small-top-header-style">
                                {/* The team that is */}
                                <Trans i18nKey = "careers.part1" />
                            </h2>
                            <br />
                            <h1 className="App-header">
                                {/* Creating the */}
                                <Trans i18nKey = "careers.part2" />
                                <span className="Purple">
                                     {/* long-term  */}
                                     <Trans i18nKey = "careers.part3" />
                                </span>
                                {/* today */}
                                <Trans i18nKey = "careers.part4" />
                            </h1>
                        </div>
                        <div
                            className="scroll"
                            style={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                                paddingBottom: 30,
                            }}
                        >
                            <FaAngleDown size="32px" color="#6432ff" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CareersPageFunction
