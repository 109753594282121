import React from "react";
//import styled, { keyframes } from "styled-components";

import "../App.css";
//import MediaQuery from "react-responsive";

import { Trans } from "react-i18next";

export default class EmailElement extends React.Component<
	{Emailnumber: number},
	{}
> {

	componentDidMount() {
		type temporary = {
			container: any,
			form: any,
			trigger: any,
			input: any,
			submitButton: any,
			successMessage: any,
			submitDelay: any,
			clickHandler: any,
			handleInputKeypress: any,
			submitForm: any
		};
		var sf: temporary = {
			container: document.querySelector('.container'+this.props.Emailnumber),
			form: document.querySelector('.container'+this.props.Emailnumber+' > #singular-form'),
			trigger: document.querySelector('.container'+this.props.Emailnumber+' > #singular-form > button#trigger'),
			input: document.querySelector('.container'+this.props.Emailnumber+'>#singular-form>#input-container>input'),
			submitButton: document.querySelector('.container'+this.props.Emailnumber+' > #singular-form > #input-container > button'),
			successMessage: document.querySelector('.container'+this.props.Emailnumber+' > #singular-form > #success'),
			submitDelay: 1500,
			clickHandler: null,
			handleInputKeypress: null,
			submitForm: null
		};

		sf.clickHandler = (e: { target: { classList: { remove: (arg0: string) => void; }; }; }) => {
			switch (e.target) {
				case sf.trigger:
					console.log('case trigger');
					sf.container.style.width = '28rem'
					e.target.classList.remove('shown');
					sf.input.classList.add('shown');
					sf.submitButton.classList.add('shown');
					sf.input.focus();
					break;
				case sf.submitButton:
					sf.submitForm();
					break;
			}
		}

		sf.handleInputKeypress = (e: { keyCode: number; preventDefault: () => void; }) => {
			if (e.keyCode === 13) {
				e.preventDefault();
				sf.submitForm();
			}
		}

		sf.submitForm = () => {
			sf.input.style.transition = 'all .4s ease';
			sf.submitButton.style.transition = 'all .4s ease';
			sf.input.classList.remove('shown');
			sf.submitButton.classList.remove('shown');
			sf.container.style.transition = 'all .4s cubic-bezier(0.47, 0.47, 0.27, 1.20) .4s';
			sf.container.style.width = '16rem';
			sf.successMessage.classList.add('shown');
			setTimeout(() => sf.form.submit(), sf.submitDelay);
		}

		sf.input.addEventListener('keypress', (e: any) => sf.handleInputKeypress(e));
		document.addEventListener('click', (e) => sf.clickHandler(e));
	}

	render() {
		return (
                <div
					style={{
						width: "100vw",
						height: "27%",
						padding: "0 2.5vw",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 2000
					}}
				>
					{/* TODO: move above divs to takeCarePage */
					/* integrate cloud function for email */
					/* Set button at top too */
					
					}
					<div className={"container" + this.props.Emailnumber}>

						<form id="singular-form" action="/" target="_self" method="post">

							<button className="shown" type="button" id="trigger">
                                {/* Sign up */}
                                <Trans i18nKey="emailelement.signup"/>
                            </button>

							<div id="input-container">
								<input type="email" name="email" placeholder="E-mail"></input>
								<button type="button">
                                    {/* Send */}
                                    <Trans i18nKey="emailelement.send"/>
                                </button>
							</div>

							<div id="success">
                                {/* We'll keep you posted! */}
                                <Trans i18nKey="emailelement.success"/>
                            </div>

						</form>
    				</div>
				</div>
		);
	}
}
